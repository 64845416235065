export const environment = {
production: true,

firebase: {
},
base_url: location.protocol + '//' + location.hostname + (location.port ? ':'+location.port : '') + location.pathname,
// api_url: 'http://206.189.142.39/webfront_QC_API/Api/',
// upload_url: 'http://206.189.142.39/webfront_QC_API/uploads/',
api_url: 'https://web-backend-uat.mcsam.in/index.php/Api/',
upload_url: 'https://web-backend-uat.mcsam.in/index.php/uploads/',
debug: false,
log: {
auth: false,
store: false,
},

smartadmin: {
api: null,
db: 'smartadmin-angular'
}

};
