import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { NotificationService } from "./notification.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private router: Router,private notificationService: NotificationService) { }
  //function which will be called for all http calls
  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    //how to update the request Parameters
    if(request.url.search('/Api/webfront/') == -1)
    {
        let user = JSON.parse(localStorage.getItem('logged'));
        request = request.clone({
            headers: new HttpHeaders({
                'Authorization':  user.key,
                'User': user.id
              })
        });
    }
    //logging the updated Parameters to browser's console
    return next.handle(request).pipe(
      tap(
        event => {
          //logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            
            
            if(event.body.Auth == false){
             
              localStorage.removeItem('logged');
              localStorage.removeItem("webfront_logo_permission");
              localStorage.removeItem("webfront_banner_permission");
              localStorage.removeItem("webfront_btn_color");
              localStorage.removeItem("webfront_header_color");
              localStorage.removeItem("webfront_header_image");
              localStorage.removeItem("webfront_header_type");
    		      localStorage.removeItem("webfront_logo");
              this.notificationService.smallBox({
                title: 'Authentication Failed!',
                content: 'User logged-in on different device',
                color: "#17a2b8",
                timeout: 5000
              });  
              this.router.navigate(["/login"]);
            }
          }
        },
        error => {
          //logging the http response to browser's console in case of a failuer
          if (event instanceof HttpResponse) {
           
          }
        }
      )
    );
  }
}