import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {config} from '@app/core/smartadmin.config';
import {Observable, throwError} from "rxjs";
import {catchError, delay, map} from 'rxjs/operators';
import {environment} from './../../../environments/environment';

@Injectable()
export class JsonApiService {

	constructor(private http: HttpClient) {
	}

	public fetch(url): Observable<any> {
		return this.http.get(this.getBaseUrl() + config.API_URL + url)
			// return this.http.get(this.getBaseUrl() + url)
			.pipe(
				delay(100),
				map((data: any) => (data.data || data)),
				catchError(this.handleError)
			)
	}

	loginApi(data: any) {

		console.log(data);
		console.log(this.getApiUrl() + 'webfront/login');
		return this.http.post<any>(this.getApiUrl() + 'webfront/login', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getMerchants(offset?, search?): Observable<any> {
		return this.http.get(this.getApiUrl() + 'admin/merchants/' + offset + '/' + search).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getMerchantsGA(offset, search, group): Observable<any> {
		return this.http.get(this.getApiUrl() + 'groupAdmin/merchants/' + offset + '/' + group + '/' + search).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	registeruser(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/register', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	merchantOnboard(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/merchant_onboard', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	verifyonboardurl(userkey) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/check_key_exists/' + userkey).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	deletemerchant(id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/delete_merchant/' + id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	createwebfront(webfrontdata) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/build_webfront', webfrontdata).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	forgotpassword(forgotpassword) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/forget_password', forgotpassword).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	resetpassword(resetpassword) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/reset_password', resetpassword).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			// catchError(this.handleError)
		)
	}

	changepassword(passwordData) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/change_password', passwordData).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	updatemerchant(merhcnatfield) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/update_merchant', merhcnatfield).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	getmerchantdata(key) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant/' + key).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getwebfront(id): Observable<any> {
		return this.http.get<any>(this.getApiUrl() + 'webfront/merchant_webfront/' + id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getWebfrontPreferences(webfront_id = 0, merchant_id = 0) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/getWebfrontPreferences/' + webfront_id + '/' + merchant_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getwebfrontsbyadmin(offset: number, search = '' , search_webfront_id='') {

		search = ( search=='' ) ? 'null' : search ;
		return this.http.get<any>(this.getApiUrl() + 'admin/webfronts/' + offset + '/' + search + '/' + search_webfront_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getwebfrontsbyGA(offset: number, group_id: number) {
		return this.http.get<any>(this.getApiUrl() + 'groupAdmin/webfronts/' + offset + '/' + group_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getwebfrontsbymerchant(key, offset, search = '', search_webfront_id='' ) {

		search = ( search=='' ) ? 'null' : search ;
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_webfronts/' + key + "/" + offset + '/' + search + '/'+ search_webfront_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getwebfrontbyid(id) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_webfront/' + id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getmerchantbyid(id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/merchant/' + id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	updatemerchantbyadmin(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/update_merchant', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	deletewebfront(id) {
		let data = {
			'id': id,
			'action_field': 'is_deleted',
			'value': 1
		}
		return this.http.post<any>(this.getApiUrl() + 'merchant/action_webfront', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	deleteInvoicePdf(payment_id) {
		let data = {
			'payment_id': payment_id
		}

		return this.http.post<any>(this.getApiUrl() + 'Webfront/delete_invoice', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	uploadInvoicePDF(file) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/uploadExcel', file).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	downloadDoc(ref_no) {

		let data = {
			'payment_id': ref_no
		}

		return this.http.post<any>(this.getApiUrl() + 'Webfront/find_invoice_pdf', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)

	}

	download_receipt(txn_id) {

		let data = {
			'txn_id': txn_id
		}
		//const headers = new HttpHeaders({'Content-Type':'application/pdf; charset=utf-8'});
		return this.http.post<any>(this.getApiUrl() + 'Webfront/download_receipt', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)

	}

	publishwebfront(id) {
		let data = {
			'id': id,
			'action_field': 'is_published',
			'value': 1
		}
		return this.http.post<any>(this.getApiUrl() + 'merchant/action_webfront', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	unpublishwebfront(id) {
		let data = {
			'id': id,
			'action_field': 'is_published',
			'value': 0
		}
		return this.http.post<any>(this.getApiUrl() + 'merchant/action_webfront', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	merchantAccess(key) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_access/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	merchantGroupInfo(key) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_group_info/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	createreceipt(data) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/custom_receipt_generation', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	createOrder(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/createOrder', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	createBulkOrder(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/createBulkOrder', data).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		)
	}

	receiptData(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/merchant_receipt_data', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transaction(offset, data) {
		let txn_type = '';
		if (data.transaction_type != undefined) {
			txn_type = data.transaction_type;
		}
		return this.http.post<any>(this.getApiUrl() + 'merchant/transactions/' + offset + '/' + txn_type, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	satransaction(offset, data) {
		let txn_type = '';
		if (data.transaction_type != undefined) {
			txn_type = data.transaction_type;
		}
		return this.http.post<any>(this.getApiUrl() + 'admin/transactions/' + offset + '/' + txn_type, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	// merchant_name
	merchantName() {
		return this.http.get<any>(this.getApiUrl() + 'admin/merchant_name').pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	flagsetting(id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/flagSetting/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	updateflagSetting(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/flagsettingUpdate', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	uploadImage(file) {
		const uploadData = new FormData();
		uploadData.append('file', file);
		return this.http.post<any>(this.getApiUrl() + "webfront/upload_image", file).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	uploadExcel(file) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/uploadExcel', file).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getRefernceList(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/references', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getpaymentwebfont(id) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/getpaymentwebfont/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getreceiptdata(key) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_receipt/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	uploadreceipt(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/uploadreceipt', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	webfrontstats() {
		return this.http.get<any>(this.getApiUrl() + 'admin/webfrontstats/').pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transactionPerMonth(key) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/monthly_transaction/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	dashboardTransaction(key) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/dashboard_transaction/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transactionPerMonthGA(data) {
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/monthly_transaction/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transactionPerMerchant() {
		return this.http.get<any>(this.getApiUrl() + 'admin/merchant_transaction').pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transactionPerMerchantGA(data) {
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/merchant_transaction', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	transactionPerWebfront(key) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/webfront_transaction/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	webfrontlist(webfront_type, key) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/webfront_list/' + webfront_type + '/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	merchantlist(key) {
		return this.http.get<any>(this.getApiUrl() + 'admin/merchant_list/' + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	webfrontbymerchant(key, id, Unpublished_webfront) {
		return this.http.get<any>(this.getApiUrl() + 'admin/webfront_by_merchant/' + key + '/' + id + '/' + Unpublished_webfront).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	sendLoginOtp(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/sendotp', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		);
	}

	viewgroups(offset) {
		return this.http.get<any>(this.getApiUrl() + 'admin/get_groups/' + offset).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	viewunassignedgroups(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/get_unassigned_groups/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	resendOnboardingEmail(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/resendOnboardingEmail/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getgroup(id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/get_group/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	addgroup(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/add_group', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	updategroup(data, id) {
		return this.http.post<any>(this.getApiUrl() + 'admin/update_group/' + id, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	deletegroup(id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/delete_group/' + id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	addgroupadmin(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/add_groupadmin', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	groupmerchantName(id) {
		return this.http.get<any>(this.getApiUrl() + 'groupAdmin/merchant_name/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	groupmerchantlist(id, key) {
		return this.http.get<any>(this.getApiUrl() + 'groupAdmin/merchant_list/' + id + "/" + key).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	groupDashboardTransaction(offset, data, id) {
		let txn_type = '';
		if (data.transaction_type != undefined) {
			txn_type = data.transaction_type;
		}
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/dashboard_transactions/' + offset + "/" + id + "/" + txn_type, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	grouptransaction(offset, data, id) {
		let txn_type = '';
		if (data.transaction_type != undefined) {
			txn_type = data.transaction_type;
		}
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/transactions/' + offset + "/" + id + "/" + txn_type, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getgroupwebfronts(offset, group_id, search = '', search_webfront_id='') {

		search = ( search=='' ) ? 'null' : search ;
		return this.http.get<any>(this.getApiUrl() + 'groupAdmin/merchants_webfronts/' + offset + "/" + group_id + "/" + search + '/' + search_webfront_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	merchantsByGroup(group_id) {
		return this.http.get<any>(this.getApiUrl() + 'admin/getMerchantsByGroup/' + group_id).pipe(
			delay(100),
			map((data: any) => (data.data || data)),
			catchError(this.handleError)
		);
	}

	getgroupdesign(group_url) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/group_design/' + group_url).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getgroup_groupadmin(id) {
		return this.http.get<any>(this.getApiUrl() + 'groupAdmin/get_group/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	updategroup_groupadmin(data) {
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/update_group/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	update_groupadmin(data) {
		return this.http.post<any>(this.getApiUrl() + 'admin/update_groupadmin/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	exportAllGroupTransactions(data) {
		let url = (data.transaction_type == 'invoices') ? 'invoices' : '';
		console.log(url);
		return this.http.post<any>(this.getApiUrl() + 'groupAdmin/exportAllGroupTransactions/' + data.group_id + '/' + url, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	exportAllTransactions(data) {
		console.log(data);

		let url = (data.transaction_type == 'invoices') ? 'invoices' : '';
		console.log(url);

		return this.http.post<any>(this.getApiUrl() + 'admin/exportAllTransactions/' + url, data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	uploadMultipalInvoices(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/upload_bulk_invoices/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	resendMailOfTransaction(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/resendMailOfTransaction', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		);
	}

	feedIndiaData(data) {

		return this.http.post<any>(this.getApiUrl() + 'webfront/feedIndiaData', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	merchantNamebyWebfront(id) {

		return this.http.get<any>(this.getApiUrl() + 'webfront/merchant_name_webfront/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	merchantNamebyUnqiue(id) {

		return this.http.get<any>(this.getApiUrl() + 'webfront/merchant_name_uniqId/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	//check if subdomain is valid for this merchant
	checkValidSubdomain(id) {

		return this.http.post<any>(this.getApiUrl() + 'webfront/checkValidSubdomain/', {'id': id}).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	//check if subdomain is valid for this merchant
	add_adhoc_payment(data) {

		return this.http.post<any>(this.getApiUrl() + 'merchant/add_adhoc_payment/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	checkFileWritten(filename) {
		console.log('json api' + filename);

		return this.http.get<any>(this.getApiUrl() + 'webfront/checkFileWritten/?file=' + filename).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	downloadErrorExcelFile(filename, count) {
		return this.http.get<any>(this.getApiUrl() + 'webfront/downloadErrorExcelWait/?file=' + filename + '&count=' + count).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	getErrorExcelFiles(merchant): Observable<any> {
		return this.http.post(this.getApiUrl() + 'merchant/getErrorExcelFiles/', merchant).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		);
	}

	getInvoice(id) {
		return this.http.get<any>(this.getApiUrl() + 'merchant/merchant_invoice/' + id).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	update_invoice(data) {
		return this.http.post<any>(this.getApiUrl() + 'merchant/edit_adhoc_payment/', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	validate_otp(data) {
		return this.http.post<any>(this.getApiUrl() + 'webfront/otp', data).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	partial_payment(txn_id: any) {
		return this.http.get<any>(this.getApiUrl() + `merchant/partial_payments/${txn_id}`).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	get_consolidated_webfronts(authKey, offset: number, search: string) {
		// return this.http.post<any>(this.getApiUrl() + `admin/consolidated_webfront/${offset}/${search}`,
		return this.http.post<any>(this.getApiUrl() + `admin/consolidated_webfront`,
		 {   'key': authKey, 
			'offset': offset ,
			'search': search ,
		 }).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	get_consolidated_webfronts_list(webfrontId: any) {

		// console.log( ' me coming inn ' ) ;
		// return this.http.post<any>(this.getApiUrl() + 'admin/abc/', {'id': webfrontId}).pipe(
		// 	delay(100),
		// 	map((data: any) => (data)),
		// 	catchError(this.handleError)
		// )

		return this.http.get<any>(this.getApiUrl() + `webfront/consolidated_webfront_merchant/${webfrontId}`).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	get_consolidated_merchants() {
		return this.http.post<any>(this.getApiUrl() + `admin/all_merchants`, {}).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	get_merchant_webfronts(merchantId: string) {
		return this.http.get<any>(this.getApiUrl() + `admin/merchant_webfront/${merchantId}`).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		)
	}

	create_consolidated_webfront(merchantIds: string, webfronts: string[]) {
		return this.http.post<any>(this.getApiUrl() + `admin/create_consolidated_webfronts`, {
			merchant_id: merchantIds,
			webfronts_id_json: webfronts
		}).pipe(
			delay(100),
			map((data: any) => (data)),
			catchError(this.handleError)
		);
	}

	private getBaseUrl() {
		return environment.base_url;
	}

	private getApiUrl() {
		return environment.api_url;
	}

	private handleError(error: any) {
		let errMsg = (error.message) ? error.message :
			error.status ? `${error.status} - ${error.statusText}` : 'Server error';
		// console.error(errMsg); // log to console instead
		return throwError(errMsg);
	}


}

