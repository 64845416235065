import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorThemeService {

  constructor() {
      this.set_btn_color();
   }

   set_btn_color() {
     console.log('Ex');
     
        let webfront_btn_color = localStorage.getItem('webfront_btn_color');
        if(webfront_btn_color) { 

          var styles = '.btn-primary { background-color: '+webfront_btn_color+' !important; border-color: '+webfront_btn_color+' !important; } .text-theme{color:'+webfront_btn_color+'}';

          var css = document.createElement('style');
          css.id="color_theme";
          css.appendChild(document.createTextNode(styles));
            
          document.getElementsByTagName("head")[0].appendChild(css);
                
        }else{
          let  color_theme= document.getElementById('color_theme');
          if(color_theme){
            color_theme.remove();
          }
        }
        // window.addEventListener('storage', (event) => {
        //   if (event.storageArea == localStorage) {
        //     if(webfront_btn_color) { 

        //       var styles = '.btn-primary { background-color: '+webfront_btn_color+' !important; }';
    
        //       var css = document.createElement('style');
        //       css.appendChild(document.createTextNode(styles));
                
        //       document.getElementsByTagName("head")[0].appendChild(css);
                    
        //     } 
        //   }else{
        //     $(".btn-primary").css("background-color: #3E90D4 !important");
        //   }
        // });
  }


}
