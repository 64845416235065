import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";

import {UserService} from "@app/core/services/user.service";

@Component({
  selector: "sa-logout",
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  public user

  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService
  ) {
  }

  showPopup() {
    this.notificationService.smartMessageBox(
      {
        title:
          "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + JSON.parse(localStorage.getItem('logged')).name+"</strong></span> ?",
        content:
          "You can improve your security further after logging out by closing this opened browser",
        buttons: "[No][Yes]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Yes") {
          this.logout();
        }
      }
    );
  }

  logout() {
    // this.userService.logout()
    localStorage.removeItem('logged');
    localStorage.removeItem("webfront_logo_permission");
    localStorage.removeItem("webfront_banner_permission");
    localStorage.removeItem('previewjson');
    localStorage.removeItem('errorFileName')
    localStorage.removeItem('errorCount')
    localStorage.removeItem('lastAction')
    localStorage.removeItem("webfront_btn_color");
    localStorage.removeItem("webfront_header_color");
    localStorage.removeItem("webfront_header_image");
    localStorage.removeItem("webfront_header_type");
    localStorage.removeItem("webfront_logo");

    let group_url = localStorage.getItem('webfront_group_url');

    if(group_url && group_url != "" && group_url != null && group_url != undefined && group_url != "undefined"){
      this.router.navigate(["/login/"+group_url]);
    }else{
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {}
}
