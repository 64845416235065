import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { JsonApiService, NotificationService } from '@app/core/services';
import { environment } from './../../../../environments/environment';
@Component({
  selector: 'sa-route-breadcrumbs',
  template: `
        <ol class="breadcrumb">
           <li *ngFor="let item of items; index as i">
            <a class="breadcrumb_link" *ngIf="i != (items.length -1)" routerLink="/{{(i==0)?url[1]:''}}{{(i==1)?url[1]+'/'+url[2]:''}}{{(i==2)?url[1]+'/'+url[2]+'/'+url[3]:''}}">{{item}}</a>
            <span *ngIf="i == (items.length -1)">{{item}}</span>
           </li>
        </ol>
  `,
  styles: []
})
export class RouteBreadcrumbsComponent implements OnInit, OnDestroy {

  public items: Array<string> = [];
  private sub
  public url: Array<string> = [];
  currentDate = new Date();
  // public errorFileName;
  // public errorCount;
  public base_url = environment.base_url;
  constructor(
    private router: Router,private jsonapi: JsonApiService
  ) { }
  // routerlink   /{{(i==0)?url[1]:''}}{{(i==1)?url[1]+'/'+url[2]:''}}
  ngOnInit() {
    this.url = this.router.url.split('/');
    this.extract(this.router.routerState.root)
    this.sub = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(v => {
      this.items = [];
      this.url = this.router.url.split('/');
      this.extract(this.router.routerState.root)
    });
    // all download links for error excel commented 30/10/2020
    // this.errorFileName = localStorage.getItem('errorFileName');
    // this.errorCount = localStorage.getItem('errorCount');
    
  }

  // downloadErrorExcel(){
  //   console.log("hi")
  //   var windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
  //   var url = environment.api_url+'Webfront/downloadErrorExcelWait?direct=true&file=' + this.errorFileName + '&count='+this.errorCount;
  //   window.open(url, "CNN_WindowName", windowFeatures);
  //   // localStorage.removeItem("errorFileName");
  //   // localStorage.removeItem("errorCount")
  //   // this.errorFileName = "";
  // }
  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }


  extract(route) {
    let pageTitle = route.data.value['pageTitle'];
    if (pageTitle && this.items.indexOf(pageTitle) == -1) {
      this.items.push(route.data.value['pageTitle'])
     }
    if (route.children) {
      route.children.forEach(it => {
        this.extract(it)
      })
    }
    if (pageTitle == 'Dashboard') {
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "Sept";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";
      var date = new Date();
      var getYear = date.getFullYear();
      this.items.push(month[date.getMonth()] + "-" + getYear);
    }
  }
  link(url) {
    
    return '/';
  }

}
