import { Component, OnInit } from '@angular/core';
import { JsonApiService } from '@app/core/services';
import { environment } from '@env/environment';
declare var $: any;

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor(private jsonapi:JsonApiService) {}
  public environment = environment;
  public header_color='';
  ngOnInit() {
      let header_color=localStorage.getItem('webfront_header_color');
      let header_type=localStorage.getItem('webfront_header_type');
      let button_theme=localStorage.getItem('webfront_btn_color');

      if(header_type == 'color'){
        $("#footerGroup").css("background-color", header_color);
      }else if (header_type == 'image' && button_theme && button_theme != ""){
        $("#footerGroup").css("background-color", button_theme);
      }

  }

}
