import { NgModule } from "@angular/core";
import { Routes, RouterModule ,PreloadAllModules} from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule",
    // canActivate: [AuthGuard]
  },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: "Home" },
    children: [
      {
        path: "home",
        loadChildren: "./features/home/home.module#HomeModule",
        data: { pageTitle: "Home" }
      }
    ]
  },


  { path: "**", redirectTo: "login" }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules,useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
