import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  isLoggedIn: boolean = false;
constructor(private router:Router){

}
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  // login(): Observable<boolean> {
  //   return of(true)
  //     .pipe(
  //       delay(1000),
  //       tap(val => this.isLoggedIn = true)
  //     )

  // }
  login(data: any){
    if(data.status){
      localStorage.setItem('logged',JSON.stringify(data));
      localStorage.setItem('webfront_group_url',data.group_url);
      this.isLoggedIn = true;
    } else{
      this.isLoggedIn = false;
    }
  }

  
  logout(): void {
    localStorage.setItem('logged','');
    localStorage.removeItem("webfront_logo_permission");
    localStorage.removeItem("webfront_banner_permission");
    localStorage.removeItem("webfront_btn_color");
    localStorage.removeItem("webfront_header_color");
    localStorage.removeItem("webfront_header_image");
    localStorage.removeItem("webfront_header_type");
    localStorage.removeItem("webfront_logo");

    this.isLoggedIn = false;
  }
}
