import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
  isUser(role: string): boolean
  {
      var user = JSON.parse(localStorage.getItem('logged'))
      if(role==user.role)
      {
        return true;
      }
      return false;
  }
}
