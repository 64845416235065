import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { JsonApiService } from '@app/core/services';
import { ColorThemeService } from '@app/core/services/color-theme.service';
import { environment } from '@env/environment';
import { empty } from 'rxjs';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  public bgcolor = "";
	public button_theme = "";
  public bgImage = "";
	public banner_type = "";

  constructor(private router: Router,private jsonapi:JsonApiService, private colorthemeservice: ColorThemeService) {
		localStorage.setItem('lastAction',Date.now().toString());

  }
  public environment = environment;
  public header_color=localStorage.getItem('webfront_header_color');
  public logo_permission=localStorage.getItem('webfront_logo_permission');
  public banner_permission=localStorage.getItem('webfront_banner_permission');
  public header_type=localStorage.getItem('webfront_header_type');
  public header_image=localStorage.getItem('webfront_header_image');
  public logo=localStorage.getItem('webfront_logo');
  ngOnInit() {
    let id = JSON.parse(localStorage.getItem('logged')).group_id;
    if(this.logo || this.logo_permission || this.header_type){
      if(this.logo_permission == "1" && this.logo){
        $("#logo img.logoimg").attr("src",this.logo);
      }
      if(this.banner_permission =="1" && this.header_type == 'image' && this.header_image){
          // $("#header").css("background-color", this.header_color);
          $("#header").css('background-image','url("'+ this.header_image +'")');
      }else if(this.banner_permission =="1" &&  this.header_type == 'color' && this.header_color){
          $("#header").css("background-color", this.header_color);
      }
    }else{
      this.jsonapi.getgroup(id).subscribe(data=>{
        if(data.status){
          if(data.group.logo == '' || data.group.logo == null || data.group.logo == undefined ){
            $("#logo img").attr("src","assets/img/logo.png");
          } else if(data.group.logo_permission == 1){    
            $("#logo img").attr("src",data.group.logo);
          }
          if(data.group.enable_banner_color && data.group.enable_banner_color == 1 && data.group.banner_type == 'image' && data.group.banner_image){
              // $("#header").css("background-color", data.group.banner_color);
              $("#header").css('background-image','url("'+ data.group.banner_image +'")');
          }else if(data.group.enable_banner_color && data.group.enable_banner_color == 1 && data.group.banner_type == 'color' && data.group.banner_color){
              $("#header").css("background-color", data.group.banner_color);
          }

          this.logo = (typeof data.group.logo != 'object')?data.group.logo:'';
					this.bgImage = (typeof data.group.banner_image != 'object')?data.group.banner_image:'';
          this.bgcolor = (typeof data.group.banner_color != 'object')?data.group.banner_color:'';
					this.banner_type = (typeof data.group.banner_type != 'object')?data.group.banner_type:'';
					this.logo_permission = (typeof data.group.logo_permission != 'object')?data.group.logo_permission:0;
					this.banner_permission = (typeof data.group.enable_banner_color != 'object')?data.group.enable_banner_color:0;
					this.button_theme = (typeof data.group.button_theme != 'object')?data.group.button_theme:'';

          localStorage.setItem('webfront_logo',this.logo);
					localStorage.setItem('webfront_logo_permission',''+this.logo_permission+'');
					localStorage.setItem('webfront_banner_permission',''+this.banner_permission+'');
					localStorage.setItem('webfront_header_color',this.bgcolor);
					localStorage.setItem('webfront_header_image',this.bgImage);
					localStorage.setItem('webfront_header_type',this.banner_type);
					localStorage.setItem('webfront_btn_color',this.button_theme);
          this.colorthemeservice.set_btn_color();
          if(this.banner_type == 'color'){
            $("#footerGroup").css("background-color", this.bgcolor);
          }else if (this.banner_type == 'image' && this.button_theme && this.button_theme != ""){
            $("#footerGroup").css("background-color", this.button_theme);
          }
        }
      });
    }
    
  }


  

  
}