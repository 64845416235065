import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute,Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromAuth from '../store/auth';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<fromAuth.AuthState>,private router:Router) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
      // check if route is restricted by role
      const currentUser = JSON.parse(localStorage.getItem('logged'));
      if(currentUser == null)
      {
        this.router.navigate(['login']);
        return false;
      }
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
          // role not authorised so redirect to home page
          
          this.router.navigate(['login']);
          return false;
      }
      
      // authorised so return true
      return true;

  // not logged in so redirect to login page with the return url
 
  }
  
}
