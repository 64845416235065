import { Component,HostListener, OnInit } from '@angular/core';
import { AutoLogoutService } from './core/services/autologout.service';
import { Router } from '@angular/router';
import { JsonApiService, NotificationService } from '@app/core/services';
import { ColorThemeService } from './core/services/color-theme.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: [AutoLogoutService]
})



export class AppComponent implements OnInit{
  constructor(private autoLogoutService: AutoLogoutService, private router:Router,
    private jsonapi: JsonApiService,
      private notificationService: NotificationService, private colorThemeService: ColorThemeService){}
    
  ngOnInit() {
    // commented for Payment page redirecting to Login page in safari 29/06/2020
  //   window.addEventListener('storage', (event) => {
  //     if (event.storageArea == localStorage) {
  //          let token = localStorage.getItem('logged');
  //          if(token == undefined) { 
  //             this.router.navigate(['/login']); 
  //          }
  //     }
  // });

  }
}
