import { Component, OnInit } from "@angular/core";
import { routerTransition } from "@app/shared/utils/animations";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { Router } from '@angular/router';
@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition],
  providers: [
    AuthGuard
  ]
})
export class MainLayoutComponent implements OnInit {
  constructor(private router:Router) {}

  ngOnInit() {
    // to redirect to Login page in all windows if logout from one 29/06/2020
    window.addEventListener('storage', (event) => {
          if (event.storageArea == localStorage) {
               let token = localStorage.getItem('logged');
               if(token == undefined) { 
                  this.router.navigate(['/login']); 
               }
          }
      });
  }

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
